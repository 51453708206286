s/* global styles in this file */
body {
  font-family: 'Inter';
  margin: 0;
  padding: 0;
  font-feature-settings: 'cv11' on, 'cv09' on, 'cv03' on, 'cv04' on, 'cv02' on, 'ss01' on !important;
}

@font-face {
  font-family: 'Inter';
  font-weight: 100;
  src: local('Inter-thin'), url('../fonts/Inter-Thin.ttf') format('truetype');
}

@font-face {
  font-family: 'Inter';
  font-weight: 400;
  src: local('Inter'), url('../fonts/Inter-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Inter';
  font-weight: 500;
  src: local('Inter-medium'), url('../fonts/Inter-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Inter';
  font-weight: 600;
  src: local('Inter-semi-bold'), url('../fonts/Inter-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Inter';
  font-weight: 700;
  src: local('Inter-bold'), url('../fonts/Inter-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Inter';
  src: local('Inter-medium'), url('../fonts/Inter-Medium.ttf') format('truetype');
  font-weight: 500;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

#HomePage-textAnimation {
  display: inline-block;
}

#HomePage-textAnimation span {
  display: inline-block;
  animation: 0.2s ease-out 0s 1 animateText;
}

@keyframes animateText {
  0% {
    transform: rotateY(180deg);
  }
  100% {
    transform: rotateY(0);
  }
}

.recharts-wrapper {
  font-family: 'Inter' !important;
  font-feature-settings: 'cv11' on, 'cv09' on, 'cv03' on, 'cv04' on, 'cv02' on, 'ss01' on !important;
}

.recharts-responsive-container {
  width: '100%' !important;
  height: '200px' !important;
}

.loading {
  clip-path: inset(0 1ch 0 0);
  animation: l 1s steps(4) infinite;
}

@keyframes l {
  to {
    clip-path: inset(0 -1ch 0 0);
  }
}

.rewards-menu {
  background: linear-gradient(-45deg, #ffd580, #f0a925, #ffe56f, #f6b547);
}

.airdrop {
  background: linear-gradient(-45deg, #fffde5, #fff2b3, #ffcc15, #994c00);
}

.launchpad {
  background: linear-gradient(-45deg, #e0f7ff, #79d1ff, #43caff, #003366);
}

.airdrop,
.launchpad,
.rewards-menu {
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  -webkit-text-fill-color: transparent;
  background-size: 200% 200%;
  animation: gradientText 3s linear infinite;
}

@keyframes gradientText {
  0% {
    background-position-x: 0%;
  }
  50% {
    background-position-x: 100%;
  }
  100% {
    background-position-x: 0%;
  }
}
