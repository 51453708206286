:root {
  --rdp-cell-size: 30px;
  --rdp-accent-color: #00ab58;
  --rdp-background-color: #e7edff;
  /* Switch to dark colors for dark themes */
  --rdp-accent-color-dark: #00ab58;
  --rdp-background-color-dark: #00ab58;
  /* Outline border for focused elements */
  --rdp-outline: 1px solid var(--rdp-accent-color);
  /* Outline border for focused and selected elements */
  --rdp-outline-selected: 2px solid rgba(0, 0, 0, 0.75);
}

.rdp-day_range_start {
  border-radius: 100% !important;
}

.rdp-day_range_end {
  border-radius: 100% !important;
}

.rdp-day_range_middle {
  border-radius: 100%;
}

.rdp-caption_label {
  border: 1px solid transparent;
  font-size: 14px !important;
  font-weight: 500;
}

.rdp-head_cell {
  font-weight: 500;
}

.rdp-nav_button {
  width: 22px;
  height: 22px;
  margin-left: 8px;
}

.rdp-nav_button:hover svg, .rdp-day:hover{
  color: white;
}
